<div>
  @if (form) {
    <div class="row-with-title">
      <div class="col-title">
        {{ 'menu.counterparties' | transloco }}
      </div>

      <div class="col-add-button">
        <app-button
          class="after-control"
          icon="add"
          [type]="btnTypes.Flat"
          label="{{ 'buttons.addCounterparty' | transloco }}"
          (onClick)="addCounterparty()"
          [size]="btnSizes.Basic"
        ></app-button>
      </div>
    </div>

    <div [ngClass]="{ 'visible-flex': isAnySelected && filteredPartiesCount}" class="row-adaptive operations-btns">
      <div class="col-adaptive operation-btn-col">
        <app-button
          [icon]="'delete'"
          [type]="btnTypes.Icon_Stroked"
          [size]="btnSizes.InOperationRow"
          [label]="'buttons.delete' | transloco"
          (onClick)="bulkDelete()"
        ></app-button>
      </div>
    </div>

    <div [ngClass]="{ hidden: isAnySelected }" [formGroup]="form">
      <div class="row-adaptive filters">
        <div class="col-adaptive main-filter-col">
          <app-search-input
            placeholder="{{ 'placeholders.filterByCounterpartyTypeName' | transloco }}"
            formControlName="counterpartyName"
            class="filter-control"
          ></app-search-input>
        </div>
      </div>
    </div>

    <div [ngClass]="{ 'visible-flex': isAnySelected && filteredPartiesCount}" class="row-adaptive selections-btns">
      <div class="col-adaptive operation-btn-col">
        <span class="selected-items-count-info">
          <span class="text">
            <b>{{ selectedPartiesCount }}</b> {{ textCountOfSelectedCounterparties | transloco }}
          </span>
        </span>
      </div>
      <div class="col-adaptive operation-btn-col" *ngIf="filteredPartiesCount > 1">
        <app-button
          [type]="btnTypes.Link"
          [size]="btnSizes.InOperationRow"
          [label]="textButtonsSelectAllCounterparties | transloco: { count: filteredPartiesCount }"
          (onClick)="selectAll()"
        ></app-button>
      </div>
      <div class="col-adaptive operation-btn-col">
        <app-button
          [type]="btnTypes.Link"
          [size]="btnSizes.InOperationRow"
          [label]="'buttons.unselectAll' | transloco"
          (onClick)="resetSelections()"
        ></app-button>
      </div>
    </div>
  }

  @if (dataRecieved && form) {
    <app-counterparties-list class="app-counterparties-table"></app-counterparties-list>
  }
</div>
