import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  Criterion,
  EventsServiceAction,
  EventsServiceData,
  SearchItemModel,
} from 'src/app/core/services/base-event-service';

import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { getFiltersFormModel } from 'src/app/core/utils/filter.utils';
import { debounceTime, filter, tap } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { selectCounterparties } from '../../store/selectors';
import { CounterpartiesActions } from '../../store/actions';
import { CounterpartySearchModel } from '../../models/counterparties.model';
import { getCounterpartiesFilterConditionsFromModel } from 'src/app/core/utils/parties.utils';
import { CounterpartySearchCriterionsFactory } from 'src/app/core/factories/counterparty-search-criterions.factory';
import { CounterpartiesListEventsService } from '../counterparties-list/counterparties-list-events.service';
import { AddCounterpartyModalComponent } from '../modals/add-counterparty-modal/add-doc-type-modal.component';
import { selectUser } from 'src/app/core/store/selectors';
import { PartyDto } from '../../services/parties-api.service';
import { isPlural } from 'src/app/shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-counterparties',
  templateUrl: './counterparties.component.html',
  styleUrl: './counterparties.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterpartiesComponent implements OnInit, OnDestroy {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public criterions: Criterion[] = this.counterpartySearchCriterionsFactory.getAllItemsAsArray();
  public conditions: SearchItemModel[] | undefined;
  public form: FormGroup | undefined;
  public dataRecieved: boolean = false;
  public selectedIds: string[] = [];
  public filteredParties: PartyDto[] | null = [];

  constructor(
    private readonly store$: Store,
    private titleService: Title,
    private bsModalService: BsModalService,
    private counterpartiesListEventsService: CounterpartiesListEventsService,
    private counterpartySearchCriterionsFactory: CounterpartySearchCriterionsFactory,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.waitUserReadyAndStart();
  }

  ngOnDestroy(): void {
    this.resetSelections();
  }

  waitUserReadyAndStart(): void {
    this.store$
      .select(selectUser)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((user) => {
        this.startInitialization();
      });
  }

  startInitialization(): void {
    this.store$.dispatch(CounterpartiesActions.getCounterparties());

    this.initForm();

    this.store$
      .select(selectCounterparties)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((data) => {
        this.dataRecieved = !!data;
        this.cdr.markForCheck();
        setTimeout(() => {
          this.resetSelections();
          this.checkAndRestoreFilters();
        }, 1);
      });

    this.titleService.setTitle('AutoLex | Counterparties');
    this.counterpartiesListEventsService.setFilterCriterions(this.criterions);
  }

  checkAndRestoreFilters() {
    const filters = this.counterpartiesListEventsService.getFilters();

    if (filters?.length > 0) {
      const model = getFiltersFormModel(filters);
      this.form?.patchValue(model);
    } else {
      this.counterpartiesListEventsService.onFilter([]);
    }
  }

  initForm() {
    this.form = this.fb.group({
      counterpartyName: [''],
    });

    this.initSubscriptions();
    this.cdr.markForCheck();
  }

  initSubscriptions(): void {
    this.form?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        tap((model: CounterpartySearchModel) => {
          this.conditions = getCounterpartiesFilterConditionsFromModel(
            model,
            this.counterpartySearchCriterionsFactory,
          );
        }),
      )
      .subscribe((value) => {
        this.counterpartiesListEventsService.resetPagination();

        if (!!this.conditions) {
          this.counterpartiesListEventsService.onFilter(this.conditions || []);
        }

        this.checkAndResetSelectAll();
      });

    this.counterpartiesListEventsService
      .getSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: EventsServiceData) => {
        switch (data.action) {
          case EventsServiceAction.Select: {
            this.selectedIds = data.id;
            this.cdr.markForCheck();
            break;
          }
        }
      });

    this.counterpartiesListEventsService
      .getDataSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: any[]) => {
        this.filteredParties = data; // filtered
        this.cdr.markForCheck();
      });
  }

  checkAndResetSelectAll() {
    if (this.conditions?.length === 0 && this.selectedIds.length === 0) {
      setTimeout(() => {
        this.resetSelections();
      });
    }
  }

  addCounterparty(): void {
    this.bsModalService.show(AddCounterpartyModalComponent, {
      initialState: {},
      class: 'modal-lg',
    });
  }

  bulkDelete(): void {
    this.store$.dispatch(CounterpartiesActions.deleteCounterparties({ ids: this.selectedIds }));
  }

  selectAll(): void {
    this.counterpartiesListEventsService.selectAll$.next(true);
  }

  resetSelections(): void {
    this.counterpartiesListEventsService.resetSelections$.next(true);
    this.selectedIds = [];
    this.cdr.markForCheck();
  }

  get isAnySelected(): boolean {
    return this.selectedIds && this.selectedIds.length > 0;
  }

  get filteredPartiesCount(): number {
    return this.filteredParties?.length || 0;
  }

  get selectedPartiesCount(): number {
    return this.selectedIds?.length || 0;
  }

  get textCountOfSelectedCounterparties(): string {
    return isPlural(this.selectedPartiesCount)
      ? 'text.countOfSelectedCounterparties'
      : 'text.countOfSelectedCounterpartiesSingle';
  }

  get textButtonsSelectAllCounterparties(): string {
    return isPlural(this.filteredPartiesCount)
      ? 'buttons.selectAllCounterparties'
      : 'buttons.selectAllCounterpartiesSingle';
  }
}
