@if (form) {
  <div class="modal-content" *let="isCounterpartyLoading$ | async as loading">
    <div class="modal-header">
      <h5 class="modal-title pull-left">{{ title }}</h5>
      <mat-icon (click)="onCancel()" class="">{{ 'close' }}</mat-icon>
    </div>

    <div class="modal-body" [formGroup]="form">
      <div class="row">
        <div class="col-sm-4 col-md-3 control-label">
          {{ 'labels.counterparty' | transloco }}
        </div>
        <div class="col">
          <app-input
            placeholder="{{ 'placeholders.addCounterparty' | transloco }}"
            formControlName="name"
          ></app-input>
        </div>
      </div>
      <div class="modal-footer" *let="isCounterpartyEditing$ | async as editing">
        <app-button
          [type]="btnTypes.Flat"
          label="{{ 'buttons.update' | transloco }}"
          [disabled]="form.invalid || !!loading || !!editing"
          [size]="btnSizes.Basic"
          (onClick)="onConfirm()"
        ></app-button>
        <app-button
          [type]="btnTypes.Stroked"
          label="{{ 'buttons.cancel' | transloco }}"
          [size]="btnSizes.Basic"
          (onClick)="onCancel()"
        ></app-button>
      </div>
    </div>
  </div>
}
